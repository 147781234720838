import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        "dark": false,
        "options": {
            "customProperties": true
        },
        "themes": {
            "dark": {
                "primary": "#afc8b3",
                "secondary": "#8db04f",
                "accent": "#72a9e8"
            },
            "light": {
                "primary": "black", //"#bac185",//"#627885",
                "secondary": "#47a358",
                "accent": "white"
            }
        }
    }
});
