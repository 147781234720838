
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  props: {
    value: Date,
    label: String,
    icon: {
      type: String,
      default: "mdi-calendar",
    },
    soloInverted: Boolean,
    flat: Boolean,
    filled: Boolean
  },
  setup(props, { emit }) {
    const datePickerMenu = ref(false);
    const dateString = computed(() => {
      if (!props.value) return null;
      return (props.value as Date).toISOString().substring(0, 10);
    });
    const handleDatePickerValue = ($event: string) => {
      emit("input", new Date($event));
      datePickerMenu.value = false;
    };
    const handleTextFieldValue = ($event: string) => {
      emit("input", new Date($event));
    };
    return {
      datePickerMenu,
      dateString,
      handleDatePickerValue,
      handleTextFieldValue,
    };
  },
});
