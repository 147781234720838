
import { defineComponent, ref } from "vue";

const createNewFormId = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    titleIcon: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    lazyValidation: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const form = ref<HTMLFormElement | null>(null);
    const formId = ref<string>(createNewFormId());
    const reset = () => {
      if (form.value) {
        form.value.reset();
      }
    };
    const resetValidation = () => {
      if (form.value) {
        form.value.resetValidation();
      }
    }
    return { formId, reset, resetValidation }
  },
});
