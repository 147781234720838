
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import useThingsStore from "../../stores/thingsStore";
import PreviewCard from "../../components/Common/PreviewCard.vue";
import { ISimpleThingViewModel } from "../../stores/thingsStoreModels";

export default defineComponent({
  components: { PreviewCard },
  setup() {
    const store = useThingsStore();
    const { recentThings, categories, focusedCategory, focusedThing } =
      storeToRefs(store);
    const { focusThing, focusCategory, initRecentThings } = store;
    initRecentThings();
    return {
      recentThings,
      categories,
      focusedThing,
      focusedCategory,
      focusCategory,
      focusThing(thing: string | ISimpleThingViewModel) {
        focusThing(thing);
      },
      beginCreateThing() {
        focusThing(null);
      },
    };
  },
});
