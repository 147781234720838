import { ref } from 'vue';
import { defineStore } from 'pinia'
import IThing from '@/crdts/IThing';
import { ChangeFn } from 'automerge';
import { dbToViewModel, ALL_CATEGORIES, ISimpleThingViewModel, ICategoryViewModel } from './thingsStoreModels';
import * as Client from '@/workers/Client';
import { chooseFileForOpen, chooseFileForSave } from '@/databases/EdjCarFile';

export default defineStore('recentThings', () => {

    const recentThings = ref<ISimpleThingViewModel[]>([]);
    const initRecentThings = async () => {
        const things = await Client.getRecentThings();
        const photoCids = things
            .map(([, t]) => t.primaryPhoto.source)
            .filter((value, index, self) => self.indexOf(value) === index)
        const photoUrls = await Client.getContentUrls(photoCids);

        const photoLookup = photoUrls.reduce((a, [cid, dataUrl]) => {
            a.set(cid, dataUrl);
            return a;
        }, new Map<string, string>());
        console.log(photoLookup);
        recentThings.value = things.map(([thingId, thing]) => {
            const photoUrl = photoLookup.get(thing.primaryPhoto.source) as string;
            console.log('thing photo', photoUrl);
            return dbToViewModel(thingId, thing, photoUrl);
        });
    };
    const addNewThing = async (thing: IThing): Promise<void> => {
        const { thingId } = await Client.addNewThing(thing);
        const primaryPhotoUrl = await Client.getContentUrl(thing.primaryPhoto.source);
        recentThings.value.unshift(dbToViewModel(thingId, thing, primaryPhotoUrl));
    };
    const getThing = async (thingId: string): Promise<IThing> => {
        const { thing } = await Client.getThing(thingId);
        return thing;
    };
    const updateThing = async (thingId: string, changeFn: ChangeFn<IThing>): Promise<void> => {
        const { thing } = await Client.updateThing(thingId, changeFn);
        const indexOf = recentThings.value.findIndex(v => v.id === thingId);
        if (indexOf !== -1) {
            const primaryPhotoUrl = await Client.getContentUrl(thing.primaryPhoto.source);
            const thingViewModel = dbToViewModel(thingId, thing, primaryPhotoUrl);
            recentThings.value.splice(indexOf, 1, thingViewModel);
        }
    };
    const exportToZip = async (): Promise<void> => {
        const blob = await Client.downloadAppStateToCar();
        await chooseFileForSave(blob, { fileName: 'toshigamiState', description: 'Export of Toshigami things' });
        // TODO: Save file handle for continuous saving.
    };
    const importFromZip = async (): Promise<void> => {
        const blob = await chooseFileForOpen('primary', { description: 'Export of Toshigami things' });
        await Client.uploadAppStateFromCar(blob);
        initRecentThings();
    };
    const focusedThing = ref<ISimpleThingViewModel | null>(null);
    const focusThing = async (thingOrThingId: ISimpleThingViewModel | string | null): Promise<void> => {
        if (thingOrThingId === null) {
            focusedThing.value = null;
        } else if (typeof thingOrThingId === 'string') {
            const { thing } = await Client.getThing(thingOrThingId);
            const primaryPhotoUrl = await Client.getContentUrl(thing.primaryPhoto.source);
            const thingViewModel = dbToViewModel(thingOrThingId, thing, primaryPhotoUrl);
            focusedThing.value = thingViewModel;
        } else {
            focusedThing.value = thingOrThingId;
        }
    };
    const focusedCategory = ref<ICategoryViewModel | null>(null);
    const focusCategory = (categoryOrCategoryId: ICategoryViewModel | string | null): void => {
        if (categoryOrCategoryId === null) {
            focusedCategory.value = null;
        } else if (typeof categoryOrCategoryId === 'string') {
            const category = ALL_CATEGORIES.find(c => c.key === categoryOrCategoryId);
            if (!category) throw new Error("Unknown category");
            focusedCategory.value = category;
        } else {
            focusedCategory.value = categoryOrCategoryId;
        }
    };
    return {
        initRecentThings,
        addNewThing,
        updateThing,
        exportToZip,
        importFromZip,
        focusThing,
        focusCategory,
        getThing,
        recentThings,
        currency: ref('AUD'),
        categories: ref([...ALL_CATEGORIES]),
        focusedCategory,
        focusedThing
    };
});