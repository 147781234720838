/*
 *   Helpful validation functions that work directly with vuetify's form element validation.
 *   NOTE: You should turn on lazy validatation in your form e.g. <v-form ref="form" :lazy-validation="true">
 *
 *   Installation (main.ts):
 *       import './plugins/validation';
 *       Vue.use(VueFragment);
 *
 *   Usage:
 *       :rules="[$rules.required(), $rules.maxLength(50)]"
 *
 *   Or, with custom messages:
 *       :rules="[$rules.required('Name is required'), $rules.maxLength(50, 'Name must be less than 50 characters')]"
 *
 *   Conditionally required:
 *       :rules="[$rules.requiredIf(() => someProp > 10), $rules.maxLength(50)]"
 *
 *   Can handle async validation:
 *       (element)
 *           <v-text-field
 *               ref="myfield"
 *               :rules="[$rules.asyncValidator({component:() => $refs.myfield, message: 'OPTIONALMESSAGE', method: someAsyncValidator})]"
 *           ></v-text-field>
 *       (async validation method)
 *           async someAsyncValidator(val) { return await this.someFunc(val); }
 */
import Vue, { VueConstructor } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Vuelidate from 'vuelidate';

export const ValidationRulesPlugin = {
    install(Vue: VueConstructor): void {
        Vue.prototype.$rules = {
            required(message?: string) {
                return (v?: string) => !!v || message || "Required";
            },
            maxLength(num: number, message?: string) {
                return (v?: string) => (v || '').length <= num || message || `Must be ${num} or less characters.`;
            },
        };
    }
};

Vue.use(Vuelidate);
Vue.use(ValidationRulesPlugin);