<template>
  <v-btn
    :type="type"
    :color="color"
    depressed
    :disabled="!valid || loading"
    :loading="loading"
    :form="formId"
    @click.prevent="$emit('click', formData)"
    :block="block"
    :outlined="outlined"
  >
    <slot
      ><v-icon left>done</v-icon
      >Submit</slot
    >
  </v-btn>
</template>

<script>
export default {
  props: {
    formData: {
      type: [Object, String, Array],
    },
    color: {
      type: String,
      default: "primary",
    },
    type: {
      type: String,
      default: "submit",
    },
    block: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Function,
      default: () => void 0,
    },
    resetValidation: {
      type: Function,
      default: () => void 0,
    },
    close: {
      type: Function,
      default: () => void 0,
    },
    loading: Boolean,
    formId: String,
    valid: Boolean,
  },
};
</script>