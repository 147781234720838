import Vue, { CreateElement } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import '@/plugins/vue-currencies';
import '@/plugins/vue-validation';
import '@/plugins/common';
import '@/scss/main.scss';
import vuetify from '@/plugins/vuetify'
import router from './router'
import pinia from '@/plugins/vue-pinia';
import VueWait from 'vue-wait';

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  wait: new VueWait(),
  pinia,
  render: (createElement: CreateElement) => createElement(App)
}).$mount('#app')
