import IThing, {
    IDocument,
    WellKnownThingCategory,
    WarrantyActionType,
    IValue,
    IWarranty
} from './IThing';
import roughFx from "@/lib/roughFx";

export const initNewThing = (name: string, quantity: number, primaryPhoto: IDocument, category: WellKnownThingCategory): IThing => {
    return {
        name,
        category,
        quantity,
        primaryPhoto,
        photos: [primaryPhoto],
        recordedTimestamp: new Date().getTime(),
        starred: false
    };
};

export const validateThingCategory = (category: string): string[] => {
    if (!category) return ['Category is required'];
    const categories: string[] = Object.values(WellKnownThingCategory);
    if (!categories.includes(category))
        return ['Category is not a well known category'];
    return [];
};

export const mutateThingCategory = (thing: IThing, category: string): void => {
    const errors = validateThingCategory(category);
    if (errors.length > 0) throw new Error(errors[0]);
    if (thing.category != category)
        thing.category = category;
};

export const validateThingName = (name: string): string[] => {
    if (!name) return ['Name is required'];
    if (name.length > 100) return ['Name maximum length is 100'];
    return [];
}

export const mutateThingName = (thing: IThing, name: string): void => {
    const errors = validateThingName(name);
    if (errors.length > 0) throw new Error(errors[0]);
    if (thing.name != name)
        thing.name = name;
};

export const validateThingQuantity = (quantity: number): string[] => {
    if (quantity <= 0) return ['Quantity must be a positive number'];
    return []
};

export const mutateThingQuantity = (thing: IThing, quantity: number): void => {
    const errors = validateThingQuantity(quantity);
    if (errors.length > 0) throw new Error(errors[0]);
    if (thing.quantity != quantity)
        thing.quantity = quantity;
};

export const validateThingDescription = (description: string | null): string[] => {
    if (description === null) return [];
    if (description.length > 500) return ['Description maximum length is 500'];
    return [];
};

export const mutateThingDescription = (thing: IThing, description: string | null): void => {
    const errors = validateThingDescription(description);
    if (errors.length > 0) throw new Error(errors[0]);
    if (description) {
        thing.description = description;
    } else {
        delete thing.description;
    }
};

export const validateCid = (cid: string): string[] => {
    if (!cid) return ['CIDs may not be null'];
    return [];
}

export const mutateThingPrimaryPhoto = (thing: IThing, photo: IDocument): void => {
    const errors = validateCid(photo.source);
    if (errors.length > 0) throw new Error(errors[0]);
    if (!thing.primaryPhoto) {
        thing.primaryPhoto = { ...photo };
    } else if (thing.primaryPhoto.source != photo.source) {
        thing.primaryPhoto.source = photo.source;
        thing.primaryPhoto.mimeType = photo.mimeType;
    }
    if (thing.photos.findIndex(v => v.source === photo.source) === -1) {
        thing.photos.push({ ...photo });
    }
};

export const validateThingPhotos = (photos: IDocument[]): string[] => {
    if (!photos || photos.length <= 0) return ['There must be at least one photo'];
    for (const photo of photos) {
        const photoValidation = validateCid(photo.source);
        if (photoValidation.length > 0) return photoValidation;
    }
    return [];
}

export const mutateThingPhotos = (thing: IThing, photos: IDocument[]): void => {
    const errors = validateThingPhotos(photos);
    if (errors.length > 0) throw new Error(errors[0]);
    if (!thing.photos) {
        thing.photos = [...photos];
    } else {
        const newPhotoCids = photos
            .map(p => p.source)
            .filter((value, index, self) => self.indexOf(value) === index);
        const currentPhotoCids = thing.photos
            .map(p => p.source)
            .filter((value, index, self) => self.indexOf(value) === index);
        for (const photo of photos.filter(r => !currentPhotoCids.includes(r.source))) {
            thing.photos.push(photo);
        }
        for (const photoCid of currentPhotoCids.filter(r => !newPhotoCids.includes(r))) {
            const thingToDeleteIndex = thing.photos.findIndex(v => v.source === photoCid);
            if (thingToDeleteIndex > -1) {
                thing.photos.splice(thingToDeleteIndex, 1);
            }
        }
    }
};

export const validateThingWeight = (weightKgs: number | null): string[] => {
    if (weightKgs === null) return [];
    if (weightKgs <= 0) return ['Weight must be a positive number'];
    return [];
}

export const mutateThingWeight = (thing: IThing, weightKgs: number | null): void => {
    const errors = validateThingWeight(weightKgs);
    if (errors.length > 0) throw new Error(errors[0]);
    if (weightKgs) {
        thing.weightKgs = weightKgs;
    } else {
        delete thing.weightKgs;
    }
};

export const validateThingVolume = (volumeMls: number | null): string[] => {
    if (volumeMls === null) return [];
    if (volumeMls <= 0) return ['Volume must be a positive number'];
    return [];
};

export const mutateThingVolume = (thing: IThing, volumeMls: number | null): void => {
    const errors = validateThingVolume(volumeMls);
    if (errors.length > 0) throw new Error(errors[0]);
    if (volumeMls) {
        thing.volumeMls = volumeMls;
    } else {
        delete thing.volumeMls;
    }
};

export const validateThingBarcode = (barcode: string | null): string[] => {
    // https://www.premierelectronics.com/blog/barcode-types-identificaton-understanding
    const MAX_AZTECH_LENGTH = 3832;
    if (barcode === null) return [];
    if (barcode.length > MAX_AZTECH_LENGTH) return ['Barcodes may not exceed 3832 characters'];
    return [];
};

export const mutateThingBarcode = (thing: IThing, barcode: string | null): void => {
    const errors = validateThingBarcode(barcode);
    if (errors.length > 0) throw new Error(errors[0]);
    if (barcode) {
        thing.barcode = barcode;
    } else {
        delete thing.barcode;
    }
};

export const validateThingWebsiteUrl = (websiteUrl: string | null): string[] => {
    if (websiteUrl === null) return [];
    try {
        new URL(websiteUrl);
    } catch {
        return ['Not a valid URL'];
    }
    return [];
};

export const mutateThingWebsiteUrl = (thing: IThing, websiteUrl: string | null): void => {
    const errors = validateThingWebsiteUrl(websiteUrl);
    if (errors.length > 0) throw new Error(errors[0]);
    if (websiteUrl) {
        thing.websiteUrl = websiteUrl;
    } else {
        delete thing.websiteUrl;
    }
};

export const validateThingDate = (date: number | null | undefined): string[] => {
    if (date === null || date === undefined) return [];
    try {
        if (date < -2209024800000) {
            return ['Thing had to be bought this century'];
        }
        return [];
    } catch {
        return ['Not a valid date'];
    }
};

export const mutateThingPurchasedDate = (thing: IThing, date: Date | null): void => {
    const newTimestamp = date ? date.getTime() : null;
    const errors = validateThingDate(newTimestamp);
    if (errors.length > 0) throw new Error(errors[0]);
    if (newTimestamp) {
        thing.purchasedTimestamp = newTimestamp;
    } else {
        delete thing.purchasedTimestamp;
    }
};

export const validateThingPurchasedFromName = (name: string | null): string[] => {
    if (name === null) return [];
    if (name.length >= 128) return ['Vendor (Purchased From) name must be 120 characters of less'];
    return [];
};

export const mutateThingPurchasedFromName = (thing: IThing, name: string | null): void => {
    const errors = validateThingPurchasedFromName(name);
    if (errors.length > 0) throw new Error(errors[0]);
    if (name) {
        thing.purchasedFromName = name;
    } else {
        delete thing.purchasedFromName;
    }
};

export const validateThingReceiptNumber = (receiptNumber: string | null): string[] => {
    if (receiptNumber === null) return [];
    if (receiptNumber.length > 128) return ['Receipt number must be 200 characters or less'];
    return [];
};

export const mutateThingReceiptNumber = (thing: IThing, receiptNumber: string | null): void => {
    const errors = validateThingReceiptNumber(receiptNumber);
    if (errors.length > 0) throw new Error(errors[0]);
    if (thing.receipt && receiptNumber) {
        thing.receipt.receiptNumber = receiptNumber;
    } else if (receiptNumber) {
        thing.receipt = { receiptNumber: receiptNumber };
    } else {
        delete thing.receipt;
    }
};

export const validateThingValue = (value: IValue | null) => {
    if (value === null) return [];
    if (value.total === null) return [];
    if (value.total < 0) return ['Costs cannot be negative. Rebates will be given a separate field.'];
    if (value.currency.length !== 3) return ['Currency code must be a valid 3 character value (e.g. "USD" or "AUD)'];
    return validateThingDate(value.timestamp);
}

export const mutateThingCost = (thing: IThing, value: IValue | null) => {
    const errors = validateThingValue(value);
    if (errors.length > 0) throw new Error(errors[0]);
    if (!value) {
        delete thing.cost;
        delete thing.costUSD;
    } else {
        const valueUSD = roughFx(value.currency, 'USD', value.total);
        thing.costUSD = valueUSD;
        if (thing.cost) {
            thing.cost.currency = value.currency;
            thing.cost.timestamp = value.timestamp;
            thing.cost.total = value.total;
            thing.cost.attestations = value.attestations;
        } else {
            thing.cost = value;
        }
    }
};

export const mutateThingSalvageValue = (thing: IThing, value: IValue | null) => {
    const errors = validateThingValue(value);
    if (errors.length > 0) throw new Error(errors[0]);
    if (!value) {
        delete thing.estSalvageValue;
        delete thing.estSalvageValueUSD;
    } else {
        const valueUSD = roughFx(value.currency, 'USD', value.total);
        thing.estSalvageValueUSD = valueUSD;
        if (thing.estSalvageValue) {
            thing.estSalvageValue.currency = value.currency;
            thing.estSalvageValue.timestamp = value.timestamp;
            thing.estSalvageValue.total = value.total;
            thing.estSalvageValue.attestations = value.attestations;
        } else {
            thing.estSalvageValue = value;
        }
    }
};

export const mutateThingReplacement = (thing: IThing, value: IValue | null) => {
    const errors = validateThingValue(value);
    if (errors.length > 0) throw new Error(errors[0]);
    if (!value) {
        delete thing.estReplacement;
        delete thing.estReplacementUSD;
    } else {
        const valueUSD = roughFx(value.currency, 'USD', value.total);
        thing.estReplacementUSD = valueUSD;
        if (thing.estReplacement) {
            thing.estReplacement.currency = value.currency;
            thing.estReplacement.timestamp = value.timestamp;
            thing.estReplacement.total = value.total;
            thing.estReplacement.attestations = value.attestations;
        } else {
            thing.estReplacement = value;
        }
    }
};

export const mutateThingIncurredUpkeep = (thing: IThing, value: IValue | null) => {
    const errors = validateThingValue(value);
    if (errors.length > 0) throw new Error(errors[0]);
    if (!value) {
        delete thing.incurredUpkeep;
        delete thing.incurredUpkeepUSD;
    } else {
        const valueUSD = roughFx(value.currency, 'USD', value.total);
        thing.incurredUpkeepUSD = valueUSD;
        if (thing.incurredUpkeep) {
            thing.incurredUpkeep.currency = value.currency;
            thing.incurredUpkeep.timestamp = value.timestamp;
            thing.incurredUpkeep.total = value.total;
            thing.incurredUpkeep.attestations = value.attestations;
        } else {
            thing.incurredUpkeep = value;
        }
    }
};

export const validateThingWarranty = (warranty: IWarranty | null): string[] => {
    if (warranty === null) return [];
    const expiryValidation = validateThingDate(warranty.expiryTimestamp);
    if (expiryValidation.length > 0) return expiryValidation;
    if (warranty.actions === null || warranty.actions === undefined) return []
    if (warranty.actions.length || 0 > 0) {
        const warrantyActions: string[] = Object.values(WarrantyActionType);
        if (warranty.actions.find(a => !warrantyActions.includes(a))) {
            return ['Supplied action type is not a valid action type'];
        }
    }
    return [];
};

export const mutateThingWarranty = (thing: IThing, warranty: IWarranty | null): void => {
    const errors = validateThingWarranty(warranty);
    if (errors.length > 0) throw new Error(errors[0]);
    if (warranty === null) {
        delete thing.warranty;
    } else if (thing.warranty === undefined) {
        thing.warranty = { ...warranty };
    } else {
        thing.warranty.actions = warranty.actions;
        thing.warranty.expiryTimestamp = warranty.expiryTimestamp;
        thing.warranty.proof = warranty.proof;
        thing.warranty.type = warranty.type;
    }
};

export const mutateThingExpiry = (thing: IThing, date: Date | null): void => {
    const newTimestamp = date ? date.getTime() : null;
    const errors = validateThingDate(newTimestamp);
    if (errors.length > 0) throw new Error(errors[0]);
    if (newTimestamp) {
        thing.expirationTimestamp = newTimestamp;
    } else {
        delete thing.expirationTimestamp;
    }
};

export const validateThingLifetimeYears = (usefulLifetimeYears: number | null): string[] => {
    if (usefulLifetimeYears === null) return [];
    if (usefulLifetimeYears <= 0) return ['Useful life time years must be a valid positive non-0 number'];
    return [];
};

export const mutateThingLifetimeYears = (thing: IThing, usefulLifetimeYears: number | null): void => {
    const errors = validateThingLifetimeYears(usefulLifetimeYears);
    if (errors.length > 0) throw new Error(errors[0]);
    if (usefulLifetimeYears) {
        thing.usefulLifetimeYears = usefulLifetimeYears;
    } else {
        delete thing.usefulLifetimeYears;
    }
};

export const mutateThingStarred = (thing: IThing, starred: boolean): void => {
    if (thing.starred != starred)
        thing.starred = starred;
};

export const validateThingRating = (rating: number | null): string[] => {
    if (rating === null) return [];
    if (rating < 1 || rating > 5) return ['Ratings must be between 1 and 5'];
    return [];
};

export const mutateThingRating = (thing: IThing, rating: number | null): void => {
    const errors = validateThingRating(rating);
    if (errors.length > 0) throw new Error(errors[0]);
    if (rating) {
        thing.rating = rating;
    } else {
        delete thing.rating;
    }
};

export const mutateThingDisposed = (thing: IThing, date: Date | null): void => {
    const newTimestamp = date ? date.getTime() : null;
    const errors = validateThingDate(newTimestamp);
    if (errors.length > 0) throw new Error(errors[0]);
    if (newTimestamp) {
        thing.disposedTimestamp = newTimestamp;
    } else {
        delete thing.disposedTimestamp;
    }
};