export type CID = string;

export interface IDocument {
    source: CID;
    mimeType: string;
}

export interface IReceipt {
    receipt?: IDocument;
    receiptNumber: string;
}

export enum WellKnownThingCategory {
    clothes = 'clothes',
    books = 'books',
    boardGames = 'boardGames',
    artworks = 'artworks',
    foodAndDrink = 'foodAndDrink',
    medicines = 'medicines',
    collectibles = 'collectibles',
    furniture = 'furniture',
    electronics = 'electronics',
    tools = 'tools',
    plants = 'plants',
    kitchenware = 'kitchenware',
    miscellaneous = 'miscellaneous'
}


export const WellKnownThingCategoryNames: { [key: string]: string } = {
    [WellKnownThingCategory.clothes]: "Clothes",
    [WellKnownThingCategory.books]: "Books",
    [WellKnownThingCategory.boardGames]: "Board games",
    [WellKnownThingCategory.artworks]: "Artworks",
    [WellKnownThingCategory.furniture]: "Furniture",
    [WellKnownThingCategory.foodAndDrink]: "Food & Drinks",
    [WellKnownThingCategory.collectibles]: "Collectibles",
    [WellKnownThingCategory.plants]: "Plants",
    [WellKnownThingCategory.kitchenware]: "Kitchenware",
    [WellKnownThingCategory.medicines]: "Medicines",
    [WellKnownThingCategory.electronics]: "Electronics",
    [WellKnownThingCategory.tools]: "Tools"
};
export const WellKnownThingCategoryPhotos: { [key: string]: string } = {
    [WellKnownThingCategory.clothes]: "/img/photos/clothes.webp",
    [WellKnownThingCategory.books]: "/img/photos/books.webp",
    [WellKnownThingCategory.boardGames]: "/img/photos/boardgames.webp",
    [WellKnownThingCategory.artworks]: "/img/photos/art.webp",
    [WellKnownThingCategory.furniture]: "/img/photos/furniture.webp",
    [WellKnownThingCategory.foodAndDrink]: "/img/photos/food.webp",
    [WellKnownThingCategory.collectibles]: "/img/photos/collectibles.webp",
    [WellKnownThingCategory.plants]: "/img/photos/plants.webp",
    [WellKnownThingCategory.kitchenware]: "/img/photos/kitchenware.webp",
    [WellKnownThingCategory.medicines]: "/img/photos/medicines.webp",
    [WellKnownThingCategory.electronics]: "/img/photos/electronics.webp",
    [WellKnownThingCategory.tools]: "/img/photos/tools.webp"
};
export const WellKnownThingCategoryIcons: { [key: string]: string } = {
    [WellKnownThingCategory.clothes]: "checkroom",
    [WellKnownThingCategory.books]: "auto_stories",
    [WellKnownThingCategory.boardGames]: "casino",
    [WellKnownThingCategory.artworks]: "color_lens",
    [WellKnownThingCategory.furniture]: "chair",
    [WellKnownThingCategory.foodAndDrink]: "restaurant",
    [WellKnownThingCategory.collectibles]: "smart_toy",
    [WellKnownThingCategory.plants]: "grass",
    [WellKnownThingCategory.kitchenware]: "soup_kitchen",
    [WellKnownThingCategory.medicines]: "medical_services",
    [WellKnownThingCategory.electronics]: "devices",
    [WellKnownThingCategory.tools]: "construction"
};

export enum NextSteps {
    keepIt = 'keepIt',
    dumpImmediately = 'dumpImmediately',
    sellItQuickly = 'sellItQuickly',
    sellForBestPrice = 'sellForBestPrice'
}

export interface IBox {
    heightCentimeters: number;
    widthCentimeters: number;
    lengthCentimeters: number;
}

export interface IValue {
    total: number;
    currency: string;
    timestamp: number;
    attestations?: IAttestation[];
}

/**
 * Warrant Types... 
 * https://www.investopedia.com/terms/w/warranty.asp#:~:text=covering%20the%20product.-,What%20Are%20the%204%20Types%20of%20Warranties%3F,conditions%20of%20quality%20and%20performance.
 * 
 * Express Warranty
 * Implied Warranty
 * Extended Warranty
 */
export enum WarrantyType {
    expressWarranty = 'expressWarranty',
    impliedWarranty = 'impliedWarranty',
    extendedWarranty = 'extendedWarranty'
}

/**
 * https://www.accc.gov.au/consumers/consumer-rights-guarantees/warranties
 */
export enum WarrantyActionType {
    compensation = 'compensation',
    repair = 'repair',
    replacement = 'replacement',
}

export interface IWarranty {
    expiryTimestamp?: number;
    proof?: IDocument;
    type: string;
    actions?: string[];
}

export interface IAttestation {
    timestamp: number;
    proof: string;
    identity: string;
}

/**
 * Anything that needs to be indexed in IndexedDB
 */
export default interface IThing {
    // Basic Details
    category: string;
    name: string;
    quantity: number;
    primaryPhoto: IDocument;
    photos: IDocument[];
    description?: string;

    // Advanced Details
    weightKgs?: number;
    volumeMls?: number;
    boundingBox?: IBox;
    barcode?: string;
    websiteUrl?: string;

    // Purchase Details
    purchasedTimestamp?: number;
    purchasedFromName?: string;
    receipt?: IReceipt;
    cost?: IValue;
    costUSD?: number; // Normalized value, not intended to be 100% accurate FX wise.
    warranty?: IWarranty;

    // Lifetime Details
    estSalvageValueUSD?: number; // Normalized value, not intended to be 100% accurate FX wise.
    estSalvageValue?: IValue;
    estReplacementUSD?: number; // Normalized value, not intended to be 100% accurate FX wise.
    estReplacement?: IValue;
    expirationTimestamp?: number;
    incurredUpkeepUSD?: number;
    incurredUpkeep?: IValue;
    usefulLifetimeYears?: number;

    // Metadata
    recordedTimestamp: number;
    starred: boolean;
    disposedTimestamp?: number;
    rating?: number;
}