import Vue from "vue";
export const formatCurrency = (val: number, code: string): string => {
    if (isNaN(val)) {
        return val.toString();
    }

    return code
        ? val.toLocaleString(undefined, {
            style: "currency",
            currency: code
        })
        : val.toLocaleString();
};
Vue.filter("currency", formatCurrency);
