
import { defineComponent, ref, watch } from "vue";
import useThingsStore from "../../stores/thingsStore";
import IThing, {
  WellKnownThingCategory,
  WarrantyActionType,
  WarrantyType,
  IDocument,
  IValue,
  IWarranty,
} from "../../crdts/IThing";
import PhotoInput, {
  IPhotoViewModel,
} from "../../components/Photos/PhotoInput.vue";
import { storeToRefs } from "pinia";
import TextDatePicker from "../../components/Common/TextDatePicker.vue";
import * as ThingCommands from "../../crdts/ThingCommands";

const KNOWN_WARRANTY_TYPES = [
  { key: null, text: "Not sure" },
  { key: WarrantyType.expressWarranty, text: "Express" },
  { key: WarrantyType.impliedWarranty, text: "Implied" },
  { key: WarrantyType.extendedWarranty, text: "Extended" },
];

const KNOWN_WARRANTY_ACTIONS = [
  { key: WarrantyActionType.compensation, text: "Compensation" },
  { key: WarrantyActionType.repair, text: "Repair" },
  { key: WarrantyActionType.replacement, text: "Replacement" },
];

export interface IFormData {
  name: string | null;
  category: string | null;
  description: string | null;
  quantity: number | null;
  photos: IPhotoViewModel[] | null;
  weightKgs: number | null;
  volumeMls: number | null;
  barcode: string | null;
  websiteUrl: string | null;
  receiptNumber: string | null;
  costPerItem: number | null;
  salvageValue: number | null;
  replacementPrice: number | null;
  incurredUpkeep: number | null;
  purchaseDate: Date | null;
  purchasedFromName: string | null;
  disposedDate: Date | null;
  expiryDate: Date | null;
  warrantyType: string | null;
  warrantyActions: (string | null)[];
  warrantyExpiry: Date | null;
  usefulLifetime: number | null;
  rating: number | null;
}

export const createViewModelFromThing = (thing: Partial<IThing>): IFormData => {
  return {
    name: thing.name || null,
    category: thing.category || WellKnownThingCategory.miscellaneous,
    description: thing.description || null,
    quantity: thing.quantity || 1,
    photos: (thing.photos || []).map((p) => ({
      takenDate: new Date(),
      primaryCid: p.source,
    })),
    weightKgs: thing.weightKgs || null,
    volumeMls: thing.volumeMls || null,
    barcode: thing.barcode || null,
    websiteUrl: thing.websiteUrl || null,
    receiptNumber: thing.receipt?.receiptNumber || null,
    costPerItem: thing.cost?.total || null,
    salvageValue: thing.estSalvageValue?.total || null,
    replacementPrice: thing.estReplacement?.total || null,
    incurredUpkeep: thing.incurredUpkeep?.total || null,
    purchaseDate: thing.purchasedTimestamp
      ? new Date(thing.purchasedTimestamp)
      : null,
    purchasedFromName: thing.purchasedFromName || null,
    disposedDate: thing.disposedTimestamp
      ? new Date(thing.disposedTimestamp)
      : null,
    expiryDate: thing.expirationTimestamp
      ? new Date(thing.expirationTimestamp)
      : null,
    warrantyType: thing.warranty?.type || null,
    warrantyActions: thing.warranty?.actions || [],
    warrantyExpiry: thing.warranty
      ? thing.warranty.expiryTimestamp
        ? new Date(thing.warranty.expiryTimestamp)
        : null
      : null,
    usefulLifetime: thing.usefulLifetimeYears || null,
    rating: thing.rating || null,
  };
};

const _convertCostToValue = (cost: number | null): IValue | null => {
  if (cost === null) return null;
  return {
    total: cost,
    currency: "AUD",
    timestamp: new Date().getTime(),
    attestations: [],
  };
};

const _convertPhotoToDocument = (photo: IPhotoViewModel): IDocument => {
  return {
    source: photo.primaryCid,
    mimeType: "image/png",
  };
};

const _convertFormToPhotos = (vm: IFormData): IDocument[] => {
  return (vm.photos || [])
    .sort((a, b) => b.takenDate.getTime() - a.takenDate.getTime())
    .map(_convertPhotoToDocument);
};

const _convertFormToWarranty = (vm: IFormData): IWarranty | null => {
  if (vm.warrantyType === null) return null;
  return {
    expiryTimestamp: (vm.warrantyExpiry as Date).getTime(),
    type: vm.warrantyType,
    actions: vm.warrantyActions.map((a) => a as string),
  };
};

/**
 * Expects validation to be applied by the form!
 */
export const assignViewModelToThing = (
  thing: IThing,
  viewModel: IFormData
): void => {
  ThingCommands.mutateThingCategory(thing, viewModel.category as string);
  ThingCommands.mutateThingName(thing, viewModel.name as string);
  ThingCommands.mutateThingQuantity(thing, viewModel.quantity as number);
  ThingCommands.mutateThingDescription(thing, viewModel.description);
  const photos: IDocument[] = _convertFormToPhotos(viewModel);
  ThingCommands.mutateThingPhotos(thing, photos);
  ThingCommands.mutateThingPrimaryPhoto(thing, photos[0]);
  ThingCommands.mutateThingWeight(thing, viewModel.weightKgs);
  ThingCommands.mutateThingVolume(thing, viewModel.volumeMls);
  ThingCommands.mutateThingBarcode(thing, viewModel.barcode);
  ThingCommands.mutateThingWebsiteUrl(thing, viewModel.websiteUrl);
  ThingCommands.mutateThingPurchasedDate(thing, viewModel.purchaseDate);
  ThingCommands.mutateThingPurchasedFromName(
    thing,
    viewModel.purchasedFromName
  );
  ThingCommands.mutateThingReceiptNumber(thing, viewModel.receiptNumber);
  ThingCommands.mutateThingCost(
    thing,
    _convertCostToValue(viewModel.costPerItem)
  );
  ThingCommands.mutateThingWarranty(thing, _convertFormToWarranty(viewModel));
  ThingCommands.mutateThingSalvageValue(
    thing,
    _convertCostToValue(viewModel.salvageValue)
  );
  ThingCommands.mutateThingReplacement(
    thing,
    _convertCostToValue(viewModel.replacementPrice)
  );
  ThingCommands.mutateThingExpiry(thing, viewModel.expiryDate);
  ThingCommands.mutateThingIncurredUpkeep(
    thing,
    _convertCostToValue(viewModel.incurredUpkeep)
  );
  ThingCommands.mutateThingLifetimeYears(thing, viewModel.usefulLifetime);
  ThingCommands.mutateThingDisposed(thing, viewModel.disposedDate);
  ThingCommands.mutateThingRating(thing, viewModel.rating);
};

export default defineComponent({
  components: { TextDatePicker, PhotoInput },
  props: { value: Boolean },
  setup(props, { emit }) {
    const store = useThingsStore();
    const {
      categories,
      addNewThing,
      updateThing,
      getThing,
      focusThing,
      exportToZip,
      importFromZip,
    } = store;
    const { focusedThing, focusedCategory } = storeToRefs(store);
    const tab = ref(0);
    const formData = ref<IFormData>(
      createViewModelFromThing({
        category:
          focusedCategory.value?.key || WellKnownThingCategory.miscellaneous,
      })
    );
    watch(
      () => focusedCategory.value,
      async (val) => {
        if (focusedThing.value) return;
        formData.value.category =
          val?.key || WellKnownThingCategory.miscellaneous;
      },
      { immediate: true, deep: true }
    );
    watch(
      () => focusedThing.value,
      async (val, oldVal) => {
        let thing: Partial<IThing> = {
          category:
            focusedCategory.value?.key || WellKnownThingCategory.miscellaneous,
        };
        if (val) {
          thing = await getThing(val.id);
          if (!oldVal) {
            emit("input", true);
          }
        }
        formData.value = createViewModelFromThing(thing);
      },
      { immediate: true, deep: true }
    );
    const closeDrawer = async () => {
      emit("input", false);
      await focusThing(null);
    };
    const buttonClick = async () => {
      if (focusedThing.value == null) {
        const photos: IDocument[] = _convertFormToPhotos(formData.value);
        const newThing = ThingCommands.initNewThing(
          formData.value.name as string,
          formData.value.quantity as number,
          photos[0],
          formData.value.category as WellKnownThingCategory
        );
        assignViewModelToThing(newThing, formData.value);
        await addNewThing(newThing);
      } else {
        await updateThing(focusedThing.value.id, (thing: IThing) => {
          assignViewModelToThing(thing, formData.value);
        });
        await closeDrawer();
      }
    };

    return {
      tab,
      categories,
      formData,
      focusedThing,
      KNOWN_WARRANTY_TYPES,
      KNOWN_WARRANTY_ACTIONS,
      buttonClick,
      closeDrawer,
      exportToZip,
      importFromZip,
    };
  },
});
