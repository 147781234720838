
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import ThingsVue from "./ThingsView.vue";
import InsightsVue from "./InsightsView.vue";
import AppBottomSheet from "./AppBottomSheet.vue";
import useThingsStore from "../../stores/thingsStore";

export default defineComponent({
  components: {
    ThingsVue,
    InsightsVue,
    AppBottomSheet,
  },
  setup() {
    const store = useThingsStore();
    const { focusThing, exportToZip, importFromZip } = store;
    const { categories } = storeToRefs(store);
    const isDrawerOpen = ref(false);
    const addFab = ref(false);
    const saveFab = ref(false);
    const tab = ref(null);
    const startCreate = () => {
      focusThing(null);
      isDrawerOpen.value = true;
    };
    return {
      tab,
      categories,
      isDrawerOpen,
      addFab,
      saveFab,
      startCreate,
      exportToZip,
      importFromZip,
    };
  },
});
