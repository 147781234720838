import IThing, {
    WellKnownThingCategoryNames,
    WellKnownThingCategoryPhotos,
    WellKnownThingCategoryIcons,
} from '@/crdts/IThing';

export interface ISimpleThingViewModel {
    id: string;
    name: string;
    quantity: number;
    category: string;
    description: string | null;
    photoUrl: string;
    thingHints: string[];
    starred: boolean;
}


export interface ICategoryViewModel {
    key: string;
    name: string;
    photoUrl: string;
    icon: string;
}

export const ALL_CATEGORIES: ICategoryViewModel[] = Object.entries(WellKnownThingCategoryNames).map(
    ([key, name]) => {
        return {
            key,
            name,
            photoUrl: WellKnownThingCategoryPhotos[key],
            icon: WellKnownThingCategoryIcons[key]
        } as ICategoryViewModel;
    }
);

export const dbToViewModel = (thingId: string, thing: IThing, photoUrl: string): ISimpleThingViewModel => ({
    id: thingId,
    name: thing.name,
    category: thing.category,
    description: thing.description || null,
    photoUrl: photoUrl,
    thingHints: [],
    starred: thing.starred,
    quantity: thing.quantity
});
