
import Main from '@/views/Persona_Hermit/MainView.vue';

export default [
    {
        path: '/',
        components: {
            default: Main,
        }
    },
];