import { IWorkerRequest, IWorkerResponse } from './models';
import * as Automerge from 'automerge';
import IThing from '@/crdts/IThing';

const worker = new Worker(new URL("./Background.ts", import.meta.url));

export const workerRequestResponse = <TRequest, TResponse>(type: string, requestData: TRequest): Promise<TResponse> => {
    return new Promise<TResponse>((resolve, reject) => {
        const request: IWorkerRequest<TRequest> = {
            data: requestData,
            requestId: Automerge.uuid(),
            type
        }
        const handler = function ({ data }: { data: IWorkerResponse<TRequest, TResponse> }) {
            if (data.requestId !== request.requestId) return;
            if (data.error) {
                reject(data.error);
            }
            else {
                resolve(data.response)
            }
            worker.removeEventListener('message', handler);
        };
        worker.addEventListener('message', handler);
        try {
            worker.postMessage(request);
        } catch {
            worker.removeEventListener('message', handler);
        }

    });
}

export const addNewPhoto = async (file: Blob): Promise<{ cid: string, photoId: string, photoUrl: string }> => {
    return await workerRequestResponse('addNewPhoto', file);
};

export const getThing = async (thingId: string): Promise<{ cid: string, thing: IThing }> => {
    return await workerRequestResponse('getThing', thingId);
}

export const addNewThing = async (thing: IThing): Promise<{ cid: string, thingId: string }> => {
    return await workerRequestResponse('addNewThing', thing);
};

export const updateThing = async (thingId: string, changeFn: Automerge.ChangeFn<IThing>): Promise<{ cid: string, thingId: string, thing: IThing }> => {
    return await workerRequestResponse('updateThing', { thingId, changeFn });
};

export const uploadAppStateFromCar = async (blob:Blob): Promise<void> => {
    await workerRequestResponse<Blob, null>('uploadAppStateFromCar', blob);
};

export const downloadAppStateToCar = async (): Promise<Blob> => {
    return await workerRequestResponse<null, Blob>('downloadAppStateToCar', null);
};

export const getContentUrl = async (cid: string): Promise<string> => {
    return await workerRequestResponse('getContentUrl', cid);
};

export const getContentUrls = async (cids: string[]): Promise<[string, string][]> => {
    return await workerRequestResponse('getContentUrls', cids);
};

export const getRecentThings = async (): Promise<[string, IThing][]> => {
    return await workerRequestResponse('getRecentThings', null);
};