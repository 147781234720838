<template>
  <v-dialog
    :value="value"
    @input="($event) => $emit('input', $event)"
    :width="width || 800"
    scrollable
    transition="dialog-bottom-transition"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template #activator="props">
      <slot name="activator" v-bind="props"></slot>
    </template>
    <slot :close="close" :open="open"></slot>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "width"],
  methods: {
    open() {
      this.$emit("input", true);
      return Promise.resolve(true);
    },
    close() {
      this.$emit("input", false);
      return Promise.resolve(false);
    },
  },
};
</script>