import { fileOpen, fileSave, FirstFileOpenOptions, FirstFileSaveOptions } from 'browser-fs-access';

export const chooseFileForOpen = async (id: string, opts: Partial<FirstFileOpenOptions<false>>): Promise<Blob> => {
    const file = await fileOpen({
        description: 'Zip file for application state',
        mimeTypes: ['application/zip'],
        extensions: ['.car'],
        startIn: 'documents',
        id,
        ...opts
    });
    const array = await file.arrayBuffer();
    return new Blob([array], { type: file.type });
};

export const chooseFileForSave = async (blob: Blob, opts: Partial<FirstFileSaveOptions>) => {
    return await fileSave(blob, {
        description: '.CAR for application state',
        mimeTypes: [blob.type],
        extensions: ['.car'],
        startIn: 'documents',
        fileName: 'appState.car',
        ...opts
    });
}