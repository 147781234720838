import Vue from 'vue';
import BackBtn from '@/components/Common/BackBtn.vue';
import FormBtn from '@/components/Common/FormBtn.vue';
import FormCard from '@/components/Common/FormCard.vue';
import FormDialog from '@/components/Common/FormDialog.vue';
import TextDatePicker from '@/components/Common/TextDatePicker.vue';
Vue.component('back-btn', BackBtn);
Vue.component('form-btn', FormBtn);
Vue.component('form-card', FormCard);
Vue.component('form-dialog', FormDialog);
Vue.component('text-date-picker', TextDatePicker);