
import { defineComponent, ref, watch } from "vue";
import { fileOpen } from "browser-fs-access";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import EasyCamera from "easy-vue-camera";
import * as Client from '../../workers/Client'

export interface IPhotoViewModel {
  takenDate: Date;
  primaryCid: string;
  primaryUrl?: string;
}

export default defineComponent({
  components: {
    "v-easy-camera": EasyCamera,
  },
  props: { value: Array },
  setup(props, { emit }) {
    const photoUrls = ref<string[]>([]);
    const showCamera = ref(false);
    const picture = ref(null);
    const handleNewPhoto = async (newPicture: Blob) => {
      showCamera.value = false;
      const { cid, photoUrl } = await Client.addNewPhoto(newPicture);
      emit("input", [
        ...(props.value || []),
        {
          takenDate: new Date(),
          primaryCid: cid,
          primaryUrl: photoUrl,
        } as IPhotoViewModel,
      ]);
    };
    const deleteImage = (i: number) => {
      (props.value || []).splice(i, 1);
      emit("input", props.value || []);
    };
    const uploadFiles = async () => {
      const files = await fileOpen({
        extensions: [".png", ".jpg"],
        mimeTypes: ["image/png", "image/jpeg"],
        description: "Photos of this thing",
        startIn: "pictures",
        multiple: true,
      });
      for (const file of files) {
        const buffer = await file.arrayBuffer();
        const blob = new Blob([ buffer ]);
        const { cid, photoUrl } = await Client.addNewPhoto(blob);
        emit("input", [
          ...(props.value || []),
          {
            takenDate: new Date(),
            primaryCid: cid,
            primaryUrl: photoUrl,
          } as IPhotoViewModel,
        ]);
      }
    };
    watch(
      () => props.value as IPhotoViewModel[] | null,
      async (val) => {
        if (!val) return;
        const missingPhotoUrls = val.filter(
          (p: IPhotoViewModel) => !p.primaryUrl
        );
        const missingPhotoUrlCids = missingPhotoUrls.map(
          (c: IPhotoViewModel) => c.primaryCid
        );
        if (missingPhotoUrlCids.length === 0) return;
        const dataUrls = await Client.getContentUrls(missingPhotoUrlCids);
        missingPhotoUrls.forEach((v: IPhotoViewModel, i: number) => {
          const [, dataUrl] = dataUrls[i];
          v.primaryUrl = dataUrl;
        });
        emit("input", [...(props.value || [])]);
      },
      { immediate: true, deep: true }
    );
    return {
      photoUrls,
      showCamera,
      picture,
      handleNewPhoto,
      deleteImage,
      uploadFiles,
    };
  },
});
